/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
	// All pages
	'common': {
	  init: function() {
		// JavaScript to be fired on all pages
	  },
	  finalize: function() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	  }
	},
	// Home page
	'home': {
	  init: function() {
		// JavaScript to be fired on the home page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
	  }
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
	  init: function() {
		// JavaScript to be fired on the about us page
	  }
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = Sage;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  /** FULL WIDTH ROWS */
		function fullWidthRows() {
		  var element = $('.container-stretch');

		  var spacing = ( $(window).width() - element.width() ) / 2 ;
		  element.css({ 
			'margin-left' : - spacing,
			'margin-right' : - spacing,
			'padding-left' : spacing,
			'padding-right' : spacing,
		  });
		}
		$(window).load( fullWidthRows );
		$(window).resize( fullWidthRows );
		/** END FULL WIDTH ROWS */

  // Load Events
  $(document).ready(UTIL.loadEvents);


/*  HEADER SEARCH  */

// Shake Animation
function shake(div) {                                                                                                                                             
	var interval = 100;
	var distance = 10;
	var times = 4;                                                                                                      
	
	$(div).css('position','relative');                                                                                  
	for(var iter = 0; iter < (times + 1); iter++){                                                                              
		$(div).animate({ left:((iter % 2 === 0 ? distance : distance * -1)) }, interval);                                   
	}                                                                                                              
	$(div).animate({ left: 0 },interval);                                                                              
} 

$('#search-button.icon-search').click(function() {
	$(this).hide();
	$('#search-button.icon-cancel').show();
	// $('#search-button.icon-cancel').toggleFade('slow');
	$('header nav.nav-primary').fadeToggle('fast', function() {
	  	$('#header-search').fadeToggle('slow');
	  	
	  	$('#header-search input[type="text"]').focus();

	});
	return false;
  });
  $('#search-button.icon-cancel').click(function() {
	  	$(this).hide();
		$('#search-button.icon-search').show();
		$('#header-search').fadeToggle('fast', function(){
		  	$('header nav.nav-primary').fadeToggle('slow');
		});
  });
  // Search Submit
  $('#header-search input').submit(function(e) { 
	var s = $(this).find('input[type="text"]'); 
	if (!s.val()) { 
		  e.preventDefault(); 
		  shake($(this).find('input[type="text"]'));
		  $(this).find('input[type="text"]').focus(); 
	}
  });

  //  MOBILE NAV

  $('#click-menu').on('click', function() {
  		$(this).toggleClass('mobile-close');
  		$('.mobile-nav').toggleClass('mobile-open');
  });

 //  $('.resource-card, .topic-card').on('click', function(e) {
 //  	e.preventDefault();
	// $(this).addClass('fullscreen');
	// $(this).parent('a')[0].click();
 //  });

  $('.back-link').on('click', function(e) {
  	e.preventDefault();
  	history.back();
  });

  $('a').each(function() {
			var a = new RegExp('/' + window.location.host + '/');
		   if(!a.test(this.href)) {
			   $(this).click(function(event) {
				   event.preventDefault();
				   event.stopPropagation();
				   window.open(this.href, '_blank');
			   });
		   }
		});

  	function process_fade() {
		var st = $(window).scrollTop();
		var top_header = $('#page-title');
		var header_title = $('.home .masthead h1, .home .masthead h3, .home .masthead p, .home .masthead a ');
		header_title.css({'opacity': 1 - (st/400)});
	}

	$(window).scroll(process_fade);

})(jQuery); // Fully reference jQuery after this point.
